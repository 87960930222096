<template>
  <div class="app-content content" :class="[{ 'show-overlay': $store.state.app.shallShowOverlay }, $route.meta.contentClass]">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper" :class="contentWidth === 'boxed' ? 'container p-0' : null">
      <slot name="breadcrumb">
      </slot>
      <div class="content-body">
        <transition :name="routerTransition" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {},
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition,
      contentWidth
    }
  }
}
</script>

<style>
</style>
