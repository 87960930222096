export default [
  {
    title: 'Story',
    route: 'home',
    icon: 'LoaderIcon'
  },
  {
    title: 'Chat',
    route: 'chat',
    icon: 'MessageSquareIcon'
  }
]
